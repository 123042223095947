<template>
    <div>
        <div class="content">
            <router-link :to="tipoDocumentoRoute" class="ml-2">
                <b-icon icon="arrow-left" size="is-small" />Atrás
            </router-link>

            <h2 class="has-text-primary-dark">
                Configuración de firma - {{ firmante }}
            </h2>
        </div>
        <div class="columns">
            <div class="column">
                <div class="is-flex is-justify-content-center">
                    <previsualizar-configuracion
                        ref="previsualizar"
                        :tipo_documento_id="$route.params.id"
                        :firmante="firmante"
                    />
                </div>
            </div>
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <opciones
                            :tipo_documento_id="$route.params.id"
                            @submit="callFetch"
                        />
                    </div>
                </div>
                <div class="card mt-2">
                    <div class="card-content">
                        <configuracion
                            :firmante="firmante"
                            :tipo_documento_id="$route.params.id"
                            @change-num-page="callFetch"
                            @load-data="callLoadData"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrevisualizarConfiguracion from '@/components/TiposDocumentos/Detail/Configuracion/PrevisualizarConfiguracion.vue';
import Configuracion from '@/components/TiposDocumentos/Configuracion.vue';
import Opciones from '@/components/TiposDocumentos/Detail/Configuracion/Opciones.vue';

export default {
    components: {
        PrevisualizarConfiguracion,
        Configuracion,
        Opciones,
    },
    data() {
        return {
            //
        };
    },
    mounted() {
        //
    },
    computed: {
        firmante() {
            return this.$route.params.firmante;
        },
        tipoDocumentoRoute() {
            return `/a/documentos/tipos-documentos/${this.$route.params.id}`;
        },
        lastConfiguracion() {
            return this.$store.state.configuracionesFirma.last[
                this.$route.params.firmante
            ];
        },
        existsConfiguracion() {
            return this.lastConfiguracion.id;
        },
    },
    methods: {
        async fetchLastConfiguracion({ tipo_documento_id, firmante }) {
            await this.$store.dispatch('configuracionesFirma/showLast', {
                tipo_documento_id,
                firmante,
            });
        },
        callFetch(num_page = 1) {
            console.log('reload document, page:', num_page);
            this.$refs.previsualizar.fetchTipoDocumentoEjemplo(num_page);
        },
        callLoadData(configuration) {
            this.$refs.previsualizar.loadData(configuration);
        },
    },
};
</script>

<style>
</style>
