<template>
    <fragment>
        <p class="">
            <b> Opciones</b>
        </p>

        <form @submit.prevent="handleSubmit">
            <b-field class="file is-secondary" :class="{ 'has-name': !!file }">
                <b-upload v-model="file" class="file-label" accept=".pdf">
                    <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label"
                            >Carga documento de vista previa (. pdf)</span
                        >
                    </span>
                    <span class="file-name" v-if="file">
                        {{ file.name }}
                    </span>
                </b-upload>
            </b-field>

            <b-button
                type="is-primary"
                native-type="submit"
                v-if="file"
                :loading="loading"
            >
                Subir
            </b-button>
        </form>
    </fragment>
</template>

<script>
import Message from '@/utils/Message';
export default {
    props: ['tipo_documento_id'],
    data() {
        return {
            loading: false,
            file: null,
        };
    },
    methods: {
        async handleSubmit() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'tiposDocumentosEjemplos/create',
                    {
                        file: this.file,
                        data: {
                            tipo_documento_id: this.tipo_documento_id,
                        },
                    }
                );
                Message.showSuccessMessage(this, res.message);
                this.clearForm();
                this.$emit('submit');
            } catch (err) {
                Message.showErrorMessage(this, err.message, err?.errors);
            } finally {
                this.loading = false;
            }
        },
        clearForm() {
            this.file = null;
        },
    },
};
</script>

<style>
</style>
