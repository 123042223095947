<template>
    <div class="configuracion--container">
        <p class="has-text-primary-dark">
            <b
                ><b-icon icon="arrow-expand-all" size="small" /> Posicionamiento
                de la firma</b
            >
        </p>
        <br />
        <div class="configuracion--form-container">
            <b-message
                v-if="!existsConfiguracion && !only_link"
                type="is-warning"
            >
                No hay una configuración de
                <b>Firma de Trabajador</b> asignada previamente.
            </b-message>
            <form @submit.prevent="createConfiguracion" v-if="!only_link">
                <b-field label="Número de página" label-position="on-border">
                    <b-input
                        v-model="formNumPage"
                        type="number"
                        name="num_page"
                        min="0"
                        max="5000"
                        required
                    />
                </b-field>
                <div class="columns">
                    <div class="column">
                        <b-field
                            label="Posición del eje X"
                            label-position="on-border"
                        >
                            <b-input
                                v-model="formPosX"
                                type="number"
                                name="pos_x"
                                :step="0.01"
                                min="0"
                                required
                            />
                        </b-field>
                        <b-field
                            label="Posición del eje Y"
                            label-position="on-border"
                        >
                            <b-input
                                v-model="formPosY"
                                type="number"
                                name="pos_y"
                                :step="0.01"
                                min="0"
                                required
                            />
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Largo" label-position="on-border">
                            <b-input
                                v-model="formW"
                                type="number"
                                name="w"
                                :step="0.01"
                                min="-1000"
                                required
                            />
                        </b-field>
                        <b-field label="Alto" label-position="on-border">
                            <b-input
                                v-model="formH"
                                type="number"
                                name="w"
                                :step="0.01"
                                min="-1000"
                                required
                            />
                        </b-field>
                    </div>
                </div>
                <div class="buttons">
                    <b-button
                        type="is-primary"
                        native-type="submit"
                        :loading="loading"
                        expanded
                    >
                        {{ existsConfiguracion ? 'Actualizar' : 'Crear' }}
                    </b-button>
                    <b-button @click="handleCancel" expanded>
                        Cancelar
                    </b-button>
                </div>
            </form>
            <div v-else>
                <div class="is-flex is-justify-content-center">
                    <b-button
                        tag="router-link"
                        :to="configuracionRoute"
                        icon-left="magnify"
                        size="is-large"
                        expanded
                    >
                        Vista previa de la firma
                    </b-button>
                </div>
                <b-message
                    v-if="!existsConfiguracion"
                    type="is-danger"
                    class="mt-2"
                >
                    No hay una configuración de
                    <b>Firma de Trabajador</b> asignada previamente.
                </b-message>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/utils/Message';

export default {
    props: ['tipo_documento_id', 'firmante', 'only_link'],
    data() {
        return {
            loading: false,
            timer: null,
        };
    },
    watch: {
        formNumPage: {
            handler(val) {
                clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                    this.$emit('change-num-page', val);
                }, 750);
            },
            deep: true,
        },
    },
    computed: {
        lastConfiguracion() {
            return this.$store.state.configuracionesFirma.last[this.firmante];
        },
        existsConfiguracion() {
            return this.lastConfiguracion.id;
        },
        configuracionRoute() {
            return `${this.tipo_documento_id}/configuracion/${this.firmante}`;
        },
        formId: {
            get() {
                return this.$store.state.configuracionesFirma.form.id;
            },
            set(value) {
                this.$store.commit('configuracionesFirma/updateFormId', value);
            },
        },
        formNumPage: {
            get() {
                return this.$store.state.configuracionesFirma.form.num_page;
            },
            set(value) {
                this.$store.commit(
                    'configuracionesFirma/updateFormNumPage',
                    value
                );
            },
        },
        formPosX: {
            get() {
                return this.$store.state.configuracionesFirma.form.pos_x;
            },
            set(value) {
                this.$store.commit(
                    'configuracionesFirma/updateFormPosX',
                    value
                );
            },
        },
        formPosY: {
            get() {
                return this.$store.state.configuracionesFirma.form.pos_y;
            },
            set(value) {
                this.$store.commit(
                    'configuracionesFirma/updateFormPosY',
                    value
                );
            },
        },
        formW: {
            get() {
                return this.$store.state.configuracionesFirma.form.w;
            },
            set(value) {
                this.$store.commit('configuracionesFirma/updateFormW', value);
            },
        },
        formH: {
            get() {
                return this.$store.state.configuracionesFirma.form.h;
            },
            set(value) {
                this.$store.commit('configuracionesFirma/updateFormH', value);
            },
        },
    },
    async mounted() {
        /* if (!this.existsConfiguracion) {
            await this.fetchLastConfiguracion({
                tipo_documento_id: this.$props.tipo_documento_id,
                firmante: this.$props.firmante
            });
        } */

        await this.fetchLastConfiguracion({
            tipo_documento_id: this.$props.tipo_documento_id,
            firmante: this.$props.firmante,
        });

        this.loadData();
    },
    methods: {
        async fetchLastConfiguracion({ tipo_documento_id, firmante }) {
            await this.$store.dispatch('configuracionesFirma/showLast', {
                tipo_documento_id,
                firmante,
            });
        },
        async createConfiguracion() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'configuracionesFirma/create',
                    {
                        ...this.$store.state.configuracionesFirma.form,
                        tipo_documento_id: this.$props.tipo_documento_id,
                        firmante: this.$props.firmante,
                    }
                );
                await this.fetchLastConfiguracion({
                    tipo_documento_id: this.$props.tipo_documento_id,
                    firmante: this.$props.firmante,
                });

                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        handleCancel() {
            this.loadData();
        },
        loadData() {
            if (!this.existsConfiguracion) {
                return;
            }

            this.$store.commit(
                'configuracionesFirma/updateFormNumPage',
                this.lastConfiguracion.num_page
            );
            this.$store.commit(
                'configuracionesFirma/updateFormPosX',
                this.lastConfiguracion.pos_x
            );
            this.$store.commit(
                'configuracionesFirma/updateFormPosY',
                this.lastConfiguracion.pos_y
            );
            this.$store.commit(
                'configuracionesFirma/updateFormW',
                this.lastConfiguracion.w
            );
            this.$store.commit(
                'configuracionesFirma/updateFormH',
                this.lastConfiguracion.h
            );

            this.$emit('load-data', this.lastConfiguracion);
        },
    },
};
</script>

<style>
</style>
