class Message
{
    static showSuccessMessage(_vue, message)
    {
        _vue.$buefy.notification.open({
            type: 'is-success',
            duration: 5000,
            closable: true,
            queue: false,
            message: message,
        });
    }

    static showErrorMessage(_vue, message, errors=null)
    {
        let templt = `<ul>`;
        if (errors) {
            for (const key in errors) {
                const error = errors[key];
                for (const i of error) {
                    templt += `<li>- ${i}</li>`;
                }
            }
        }
        templt += `</ul>`;

        _vue.$buefy.notification.open({
            type: 'is-danger',
            duration: 7000,
            closable: true,
            queue: false,
            message: `${message}` + (errors ? `<br />${templt}` : ''),
        });
    }
}

export default Message;
