<template>
    <div class="is-relative">
        <b-loading :is-full-page="false" v-model="loading"></b-loading>
        <canvas
            class="canvas"
            ref="canvas"
            @mousedown="handleMouseDown"
            @mouseup="handleMouseUp"
            @mousemove="handleMouseMove"
        >
        </canvas>
    </div>
</template>

<script>
import Message from '@/utils/Message';
import { roundDecimals } from '@/utils/Numbers';

export default {
    name: 'PrevisualizarConfiguracion',
    props: {
        imageSrc: {
            default:
                'https://dummyimage.com/495x700/ededed.jpg&text=Hoja+A4+(210x297)',
        },
        tipo_documento_id: {
            type: String,
        },
        firmante: {
            type: String,
            default: 'TRABAJADOR',
        },
    },
    data() {
        return {
            mouse: {
                current: {
                    x: 0,
                    y: 0,
                },
                previous: {
                    x: 0,
                    y: 0,
                },
                down: false,
            },
            rect: {
                pos_x: 0,
                pos_y: 0,
                w: 0,
                h: 0,
            },
            img: null,
            loading: false,
            factorConversion: 495 / 595,
        };
    },
    computed: {
        blobUrl() {
            return this.$store.state.tiposDocumentos.blobUrl;
        },
        lastConfiguracion() {
            return this.$store.state.configuracionesFirma.last[this.firmante];
        },
    },
    async mounted() {
        this.configCanvasAndLoadImage();

        await this.fetchTipoDocumentoEjemplo();
    },
    methods: {
        async fetchTipoDocumentoEjemplo(num_page = 1) {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'tiposDocumentos/viewEjemplo',
                    {
                        id: this.tipo_documento_id,
                        data: {
                            num_page: num_page,
                        },
                    }
                );
                if (this.blobUrl) {
                    this.img.src = this.blobUrl;

                    this.img.onload = () => {
                        this.loadData(this.lastConfiguracion);
                    };
                }
            } catch (err) {
                // FIXME: No puede obtener el mensaje de error de la api, retorna un Blob

                this.img.src = this.imageSrc;

                this.img.onload = () => {
                    this.loadData(this.lastConfiguracion);
                };

                console.log('No hay un documento de vista previa disponible', err);
            } finally {
                this.loading = false;
            }
        },
        configCanvasAndLoadImage() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');

            ctx.translate(0.5, 0.5);
            ctx.imageSmoothingEnabled = false;

            const img = new Image();
            img.src = this.imageSrc;
            this.img = img;

            this.img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(this.img, 0, 0);
            };

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(this.img, 0, 0);
        },
        draw() {
            if (this.mouse.down) {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(this.img, 0, 0);
                ctx.setLineDash([2]);
                ctx.strokeRect(
                    this.rect.pos_x,
                    this.rect.pos_y,
                    this.rect.w,
                    this.rect.h
                );
                ctx.globalAlpha = 0.2;
                ctx.fillRect(
                    this.rect.pos_x,
                    this.rect.pos_y,
                    this.rect.w,
                    this.rect.h
                );
                ctx.globalAlpha = 1.0;
            }
        },
        loadData(configuration) {
            const rect = this.conversionPdfToCanvasDimensions(configuration);

            this.rect.pos_x = rect.pos_x;
            this.rect.pos_y = rect.pos_y;
            this.rect.w = rect.w;
            this.rect.h = rect.h;

            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(this.img, 0, 0);
            ctx.setLineDash([2]);
            ctx.strokeRect(rect.pos_x, rect.pos_y, rect.w, rect.h);
            ctx.globalAlpha = 0.2;
            ctx.fillRect(rect.pos_x, rect.pos_y, rect.w, rect.h);
            ctx.globalAlpha = 1.0;
        },
        handleMouseDown(event) {
            this.mouse.down = true;
            this.mouse.current = {
                x: event.offsetX,
                y: event.offsetY,
            };
            this.rect.pos_x = this.mouse.current.x;
            this.rect.pos_y = this.mouse.current.y;
        },
        handleMouseUp() {
            this.mouse.down = false;

            let newRect = this.calculateNewOriginPoint(this.rect);
            newRect = this.conversionCanvasToPdfDimensions(newRect);

            this.$store.commit(
                'configuracionesFirma/updateFormPosX',
                newRect.pos_x
            );
            this.$store.commit(
                'configuracionesFirma/updateFormPosY',
                newRect.pos_y
            );
            this.$store.commit('configuracionesFirma/updateFormW', newRect.w);
            this.$store.commit('configuracionesFirma/updateFormH', newRect.h);
        },
        handleMouseMove(event) {
            this.mouse.current = {
                x: event.offsetX,
                y: event.offsetY,
            };
            if (this.mouse.down) {
                this.rect.w = this.mouse.current.x - this.rect.pos_x;
                this.rect.h = this.mouse.current.y - this.rect.pos_y;
                this.draw(event);
            }
        },
        calculateNewOriginPoint({ pos_x, pos_y, w, h }) {
            return {
                pos_x: w >= 0 ? pos_x : pos_x + w,
                pos_y: h >= 0 ? pos_y + h : pos_y,
                w: w >= 0 ? w : -1 * w,
                h: h <= 0 ? h : -1 * h,
            };
        },
        conversionCanvasToPdfDimensions({ pos_x, pos_y, w, h }) {
            return {
                pos_x: roundDecimals(pos_x / this.factorConversion),
                pos_y: roundDecimals((700 - pos_y) / this.factorConversion),
                w: roundDecimals(w / this.factorConversion),
                h: roundDecimals(h / this.factorConversion),
            };
        },
        conversionPdfToCanvasDimensions({ pos_x, pos_y, w, h }) {
            return {
                pos_x: roundDecimals(pos_x * this.factorConversion),
                pos_y: 700 - roundDecimals(pos_y * this.factorConversion),
                w: roundDecimals(w * this.factorConversion),
                h: roundDecimals(h * this.factorConversion),
            };
        },
    },
};
</script>

<style>
.canvas {
    border: 1px solid #ededed;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
        0 0px 0 1px rgb(10 10 10 / 2%);
    cursor: crosshair;
}
</style>
